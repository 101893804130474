.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #2e1b4e, #765572 48%, #f1751c);
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 2px;
  padding: 3px;
  border: 0px;
  border-radius: 0px;
  width: 200px;
  
}

button {
  border: none;
  background-color: #FF3F34;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.link-btn {
  padding: 10px;
  background: white;
  color: #FF3F34;
  font-weight: bold;
  text-decoration: none;

}
.tog-btn{
  border: none;
  background: none;
  text-decoration: underline;
}